import modal from './modal'
import _ from 'lodash'

/**
 *
 * @ok: Function
 * @cancel: Function
 * @closeable: Boolean => true || false
 * @transition: String => 'up' || 'down' || 'scale'
 * @position: String => 'top' || 'flex'
 * @flex: Boolean => false || true   优先级高于position,position已经过时.
 * @styles: Object || nuill
 * @children: Array || Object || String
 * @mask: Boolean => true || false
 * @opacity: Number => 0.6 || [0 - 1]
 * @router: Object => 注入router
 * @store: Object => 注入store
 * @data: Object => 注入data
 *
 * children: slotbox,
 * children: 'hello world ~~~!',
 * children: {component: slotbox, options: {}, children: 'hello world ~~~!'},
 * children: function(h,$data){ return h(slotbox); }
 * children: [['h1', 'xxxx']]
 *
 */

export default {
  install(Vue) {
    Vue.component('modal', modal)

    Vue.prototype.$vModal = ({
      ok = function() {},
      cancel = function() {},
      closeable = true,
      visible = false,
      transition = 'up',
      position = 'center',
      flex = position === 'flex' ? true : false,
      styles,
      modalStyles,
      mask,
      opacity,
      store,
      router,
      data = function() {
        return {}
      },
      target,
      children = []
    } = {}) => {
      let div = document.createElement('div')

      if (!target || !Object.prototype.toString.call(target).indexOf('HTML') > -1 || target.nodeType === 1) {
        target = document.body
      }
      target.appendChild(div)

      const _modal = new Vue({
        el: div,
        store,
        router,
        data,
        render: function(createElement) {
          const _this = this
          return createElement(
            modal, {
              // Object.assign({}, this.$data, {})
              props: {
                global: true,
                styles,
                modalStyles,
                transition,
                position,
                flex,
                closeable,
                visible,
                mask,
                opacity
              },
              on: {
                ok,
                cancel
              },
              ref: 'modal'
            },
            childCreator(createElement, children, this.$data)
          )
        },
        created() {
          this.target = target
        },
        methods: {
          remove() {
            this.$destroy()
          },
          open(data) {
            this.$refs.modal.open(data)
          },
          close() {
            this.$refs.modal.close()
          },
          ok(data) {
            this.$refs.modal.ok(data)
          },
          cancel(data) {
            this.$refs.modal.cancel(data)
          }
        },
        destroyed() {
          this.target = null
          div = null
        }
      })
      // return _modal.$children[0];
      return _modal
    }

    function childCreator(createElement, children, $data) {
      const _VNodes = []
      if (!_.isArray(children)) {
        children = [children]
      }
      _.forEach(children, (child, key) => {
        const vnode = vnodeCreator(createElement, child, $data)
        !!vnode && _VNodes.push(vnode)
      })
      return _VNodes
    }

    function vnodeCreator(createElement, component, $data) {
      switch (true) {
        case _.isFunction(component):
          return component(createElement, $data)
        case _.isString(component):
          return component
        case _.isPlainObject(component):
          if (component._scopeId) {
            return createElement(component, {
              props: Object.assign({}, $data)
            })
          } else if (component.component) {
            component.options = component.options
            component.options.props = Object.assign(component.options.props || {}, $data)
            return createElement(
              component.component,
              component.options,
              childCreator(createElement, component.children, $data),
              // [].concat(vnodeCreator(createElement, component.children, $data))
            )
          }
          return null
        case _.isArray(component):
          component = [].slice.apply(component)
          if (!component[1] || _.isPlainObject(component[1])) {
            component[1] = component[1] || {}
            component[1].props = Object.assign(component[1].props || {}, $data)
          }
          return createElement.apply(null, component)
        default:
          return null
      }
    }

    Vue.prototype.$vModal.vnodeCreator = vnodeCreator
    Vue.prototype.$vModal.childCreator = childCreator
    Vue.prototype.$vModal.TOP = 'top'
    Vue.prototype.$vModal.CENTER = 'flex'
    Vue.prototype.$vModal.BOTTOM = 'bottom'
  }
}
