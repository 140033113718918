import size from 'lodash/size'
import pick from 'lodash/pick'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import {
  getAppid
} from '@/api/getUrlParmas'
import {
  setToken,
  removeToken
} from '@/utils/auth'
import api from '@/api/index'
import {
  userDB
} from '@/db'

const {
  getWxInfo,
  getToken,
  getUserInfo,
  getUserBind,
  getSmsCode,
  userBind,
  getUserCars,
  getCarDetail,
  addCar
} = api

const SET_TOKEN = 'SET_TOKEN'
const SET_NAME = 'SET_NAME'
const SET_VIPID = 'SET_VIPID'
const GET_USER_INFO = 'GET_USER_INFO'
const GET_REGISTER_SHOPS = 'GET_REGISTER_SHOPS'
const CHOOSE_REGISTER_SHOP = 'CHOOSE_REGISTER_SHOP'
const GET_USER_CARS = 'GET_USER_CARS'
const GET_CAR_DETAIL = 'GET_CAR_DETAIL'

const LOGOUT_USER = 'LOGOUT_USER'
const SET_APPID = 'SET_APPID'
const SET_USERINFOR = 'SET_USERINFOR'
const SET_METERLIST = 'SET_METERLIST'
const SET_RECHARGEFEELIST = 'SET_RECHARGEFEELIST'

const state = {
  token: '',
  name: '',
  vipId: '',
  userInfo: {},
  registerShops: [],
  chooseShop: {},
  cars: [],
  car: {},
  // appId: getAppid(),
  appId: 'wx5a503333e3a20439',
  meterlist: {},
  rechargeFeeList: {
    rechrge: {},
    fee: {}
  },
  userInfor: {
    avgFee: 0,
    balance: 0,
    companyId: '1',
    createTime: '2018-07-26T09:13:33',
    id: 'ab315ca9-d644-409d-ae53-ccb6e4a41cf99',
    name: '',
    openId: 'oz-zm0pTWU5IRDBGo1m1lqkl6knk',
    phone: '13600852346',
    photo: '',
    remainingDays: 0,
    sex: 'MAN',
    todayReading: 0,
    tomorrowReading: 0
  }
}

const getters = {
  hasRegisterShops: state => size(state.registerShops),
  hasCar: state => !isEmpty(state.car),
  hasBindShop: state => state.userInfo && state.userInfo.shopId
}

const mutations = {
  [SET_TOKEN](state, token) {
    userDB.set('token', token)
    state.token = token
  },
  [SET_RECHARGEFEELIST](state, list) {
    state.rechargeFeeList = list
  },
  [SET_NAME](state, name) {
    state.name = name
  },
  [SET_VIPID](state, vipId) {
    userDB.set('vipId', vipId)
    state.vipId = vipId
  },
  [SET_APPID](state, appId) {
    state.appId = appId
    userDB.set('appId', appId)
  },
  [SET_USERINFOR](state, userInfor) {
    state.userInfor = Object.assign(state.userInfor, userInfor)
  },
  [SET_METERLIST](state, meterlist) {
    state.meterlist = Object.assign(state.meterlist, meterlist)
  },
  [GET_USER_INFO](state, userInfo) {
    state.userInfo = userInfo
  },
  [GET_REGISTER_SHOPS](state, list) {
    state.registerShops = list
  },
  [CHOOSE_REGISTER_SHOP](state, shop) {
    state.chooseShop = shop
  },
  [GET_USER_CARS](state, cars) {
    state.cars = cars
  },
  [GET_CAR_DETAIL](state, car) {
    state.car = car
  },
  [LOGOUT_USER](state) {
    state.vipId = ''
    state.token = ''
    userDB.remove('vipId')
    userDB.remove('token')
  }
}

const actions = {
  async getWxAppId({
    commit
  }, appId) {
    try {
      commit(SET_APPID, appId)
      // console.log(wxInfo, 'wxInfo')
      return appId
    } catch (error) {}
  },
  async getWxPath({
    commit
  }) {
    try {
      const wxInfo = await getWxInfo(state.appId)
      return wxInfo
    } catch (error) {
      alert(`${error},微信跳转getWxPath接口失败`)
    }
  },
  async transferCode({
    commit
  }, code) {
    try {
      const tokenInfo = await getToken(code, state.appId)
      const {
        token,
        vipId
      } = tokenInfo
      commit(SET_TOKEN, token)
      commit(SET_VIPID, vipId)
      return tokenInfo
    } catch (error) {
      console.log('get token failed', error)
      return Promise.reject(error)
    }
  },
  async sendSms({
    commit
  }, mobile) {
    try {
      const sms = await getSmsCode(mobile, state.appId)
      return sms
    } catch (error) {
      console.log('get sms failed', error)
      return Promise.reject(error)
    }
  },
  async userLogin({
    commit
  }, value) {
    try {
      const userInfo = await getUserBind(value)
      const {
        token,
        vipId,
        list = []
      } = userInfo
      const len = size(list)
      if (!len) { // list 不为空， token 返回空
        commit(SET_TOKEN, token)
        commit(SET_VIPID, vipId)
      } else {
        commit(GET_REGISTER_SHOPS, list)
      }
      return userInfo
    } catch (error) {
      console.warn(error)
      console.log('user binding failed', error)
      return Promise.reject(error)
    }
  },
  async fetchUser({
    commit,
    dispatch
  }) {
    try {
      const userInfo = await getUserInfo()
      const {
        shopId = '', carId = ''
      } = userInfo
      if (shopId) {
        const shop = await dispatch('fetchShopDetail', shopId)
        const _employees = await dispatch('fetchEmployees', {
          shopId
        })
        const activity = await dispatch('fetchActivity', {
          shopId
        })
        const employees = map(_employees, emp => {
          const {
            id,
            name,
            photo
          } = emp
          return {
            id,
            name,
            photo
          }
        })
        const _shop = pick(shop || {}, ['id', 'name', 'lat', 'lng', 'itemList'])

        userInfo.shopDetail = {
          ...cloneDeep(_shop),
          employees,
          activity
        }
      }
      carId && dispatch('fetchCarDetail', carId)
      commit(GET_USER_INFO, userInfo)
      return userInfo
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async userBindShop({
    commit
  }, shop = {}) {
    const {
      vipId
    } = shop
    commit(CHOOSE_REGISTER_SHOP, shop)
    commit(SET_VIPID, vipId)
    try {
      const res = await userBind(vipId)
      const {
        token
      } = res || {}
      commit(SET_TOKEN, token)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async fetchUserCars({
    commit
  }, vipId) {
    try {
      const cars = await getUserCars(vipId)
      const {
        list = []
      } = cars
      commit(GET_USER_CARS, list)
      return list
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async fetchCarDetail({
    commit
  }, carId) {
    try {
      const car = await getCarDetail(carId)
      commit(GET_CAR_DETAIL, car)
      return car
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async userAddCar({
    commit
  }, value) {
    try {
      const res = await addCar(value)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  },
  logoutUser({
    commit
  }) {
    commit(LOGOUT_USER)
  },
  // 获取用户信息
  GetInfo({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      if (state.vipId === '' || state.vipId === undefined) {
        reject('报错了')
      } else {
        resolve()
      }
      // executeS('systemUser').then(response => {
      //   const data = response.data
      //   if (data.success) {
      //     commit('SET_NAME', data.result.name)
      //     // commit('SET_AVATAR', data.result.photo)
      //     // commit('SET_USERTYPES', data.result.userType)
      //     resolve(response)
      //   } else {
      //     reject(data.message)
      //   }
      // }).catch(error => {
      //   reject(error)
      // })
    })
  },

  // 登出
  LogOut({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      // executeS('userLogout', state.token).then(response => {
      //   const data = response.data
      //   if (data.success) {
      //     commit(SET_TOKEN, '')
      //     commit(SET_USERTYPES, '')
      //     commit(SET_ROLES, [])
      //     removeToken()
      //     resolve()
      //   } else {
      //     reject(data.message)
      //   }
      // }).catch(error => {
      //   reject(error)
      // })
    })
  },
  // 前端 登出
  FedLogOut({
    commit
  }) {
    return new Promise(resolve => {
      commit(SET_TOKEN, '')
      removeToken()
      resolve()
    })
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
