import request from '@/utils/request'
import { userDB } from '@/db'

const scope = ''

export function getWxSDKSign(url) {
  return request({
    method: 'get',
    url: `${scope}/api/wechat/signature`,
    params: {
      url
    }
  })
}

export default {
  /*
    获取接口数据
    @parmas obj(接口所需要的参数) url(接口地址) method(传输方式)
  */
  fetchData(obj, url, method, parmas = {}) {
    let data = { params: obj }
    if (method === 'put') {
      data = { data: obj }
    }
    return request({
      method,
      url: `${scope}${url}`,
      ...parmas,
      ...data
    })
  },
  /*
  获取微信配置信息
  @parmas appid(微信的appid)
  */
  getWxConfig(appid) {
    const requestUrl = location.href.split('#')[0] || location.href
    const url = requestUrl
    return request({
      method: 'get',
      url: `/api/wechat/signature/${appid}`,
      params: {
        url
      },
      noToken: true
    })
  },
  /*
  获取微信支付接口信息
  @parmas appid(微信的appid) amount(金额)
  */
  getWxRecharge(amount, appid) {
    return request({
      method: 'get',
      url: `${scope}/running/recharge/wechat/${appid}`,
      params: {
        amount
      },
      noToken: true
    })
  },
  getWxInfo (appid) {
    const requestUrl = encodeURIComponent(process.env.VUE_APP_APP_ORIGIN)
    return request({
      method: 'get',
      url: `/api/wechat/authorizationUrl/${appid}`,
      params: {
        requestUrl,
        scope: 'snsapi_base'
      },
      noToken: true
    })
  },
  getToken (code, appid) {
    return request({
      method: 'post',
      //url: `${scope}/auth/vip/login`,
      url: `/auth/vip/login`,
      data: {
        code,
        appId: appid
      },
      noToken: true
    })
  },
  getSmsCode(phone, appid) {
    return request({
      method: 'get',
      url: `${scope}/auth/validCode`,
      params: {
        phone,
        appId: appid
      }
    })
  },
  refreshToken(token) {
    return request({
      method: 'get',
      url: `${scope}/auth/refresh`,
      params: {
        token
      }
    })
  },
  getUserBind({
    phone = '',
    validCode = '',
    appId = ''
  } = {}) {
    return request({
      method: 'put',
      url: `${scope}/archive/vip/binding`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        phone,
        validCode,
        appId
      }
      // params: {
      //   phone,
      //   validCode
      // }
    })
  },
  userBind(vipId) {
    return request({
      method: 'put',
      url: `${scope}/archive/vip/${vipId}/binding`
    })
  },
  userLogout(token) {
    return request({
      method: 'get',
      url: `${scope}/auth/logout`,
      params: {
        token
      }
    })
  },
  getBrandList() {
    return request({
      method: 'get',
      url: `${scope}/archive/brands/list`
    })
  },
  getShopList({
    orgId = '',
    name = '',
    pageNum = 1,
    pageSize = 20,
    lat = '',
    lng = ''
  } = {}) {
    return request({
      method: 'get',
      url: `${scope}/archive/shops/page`,
      params: {
        orgId,
        name,
        lat,
        lng,
        'page.pageNum': pageNum,
        'page.pageSize': pageSize
      }
    })
  },
  getShopDetail(id) {
    return request({
      method: 'get',
      url: `${scope}/archive/shop/${id}`
    })
  },
  getActivity({
    state,
    shopId
  } = {}) {
    return request({
      method: 'get',
      url: `${scope}/market/activities`,
      params: {
        state,
        shopId
      }
    })
  },
  getEmployees({
    shopId = '',
    name = '',
    phone = ''
  } = {}) {
    return request({
      method: 'get',
      url: `${scope}/archive/employees`,
      params: {
        shopId,
        name,
        phone
      }
    })
  },
  getAds({
    type = 'VIP_HOME'
  } = {}) {
    return request({
      method: 'get',
      url: `${scope}/market/adverts`,
      params: {
        type
      }
    })
  },
  getUserInfo() {
    return request({
      method: 'get',
      url: `${scope}/archive/vip`
    })
  },
  getUserCars(vipId) {
    return request({
      method: 'get',
      url: `${scope}/archive/cars`,
      params: { vipId }
    })
  },
  getCarDetail(id) {
    return request({
      method: 'get',
      url: `${scope}/archive/car/${id}`
    })
  },
  addCar(data = {}) {
    return request({
      method: 'post',
      url: `${scope}/archive/car`,
      data
    })
  },
  getPicc({
    lng = '',
    lat = ''
  } = {}) {
    return request({
      method: 'get',
      url: `${scope}/archive/branches`,
      params: {
        lng,
        lat
      }
    })
  },
  getBookList({
    shopId = '',
    employeeId = '',
    state = '',
    pageNum = 1,
    pageSize = 20
  } = {}) {
    return request({
      method: 'get',
      url: `${scope}/running/appointments/page`,
      params: {
        shopId,
        employeeId,
        state,
        'page.pageNum': pageNum,
        'page.pageSize': pageSize
      }
    })
  },
  getBookDetail(id) {
    return request({
      method: 'get',
      url: `${scope}/running/appointment/${id}`
    })
  },
  bookProject({
    shopId = '',
    employeeId = '',
    itemCode = '',
    appointmentTime = +new Date(),
    longDesc = ''
  } = {}) {
    return request({
      method: 'post',
      url: `${scope}/running/appointment`,
      data: {
        shopId,
        employeeId,
        itemCode,
        appointmentTime,
        longDesc
      }
    })
  },
  cancelBook({
    id = '',
    resuseReason = '临时有事',
    longDesc = ''
  } = {}) {
    return request({
      method: 'put',
      url: `${scope}/running/appointment/${id}/cancel`,
      data: {
        resuseReason,
        longDesc
      }
    })
  },
  getOrderList({
    pageNum = 1,
    pageSize = 20
  } = {}) {
    return request({
      method: 'get',
      url: `${scope}/running/orders/page`,
      params: {
        'page.pageNum': pageNum,
        'page.pageSize': pageSize
      }
    })
  },
  getOrderDetail(id) {
    return request({
      method: 'get',
      url: `${scope}/running/order/${id}`
    })
  },
  orderRate({
    orderId,
    star = 5,
    content,
    complainFlag = 'N',
    longDesc
  } = {}) {
    return request({
      method: 'post',
      url: `${scope}/running/orderComment`,
      data: {
        orderId,
        star,
        content,
        complainFlag,
        longDesc
      }
    })
  },
  getVideos({
    title = '',
    employeeId = '',
    shopId = '',
    orgId = '',
    state = '',
    pageNum = 1,
    pageSize = 20
  } = {}) {
    return request({
      method: 'get',
      url: `${scope}/videos/page`,
      params: {
        title,
        employeeId,
        shopId,
        orgId,
        state,
        'page.pageNum': pageNum,
        'page.pageSize': pageSize
      }
    })
  }
}

