import axios from 'axios'
import Vue from 'vue'
// import { Message } from 'element-ui'
import store from '@/store'
import { userDB } from '@/db'
import _ from 'lodash'
import router from '@/router'
import { Dialog } from 'vant'
require('promise.prototype.finally').shim()

function paramFilter(obj) {
  return _.pickBy(obj, value => !!value)
}

let URL = ''
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  timeout: 30000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json'
  },
  validateStatus: function(status) {
    return status >= 200 && status < 300 // default
  }
})
const start = _.debounce(function() {
  Vue.prototype.$Progress.start()
}, 150)
const end = _.debounce(function() {
  Vue.prototype.$Progress.finish()
}, 150)
// request拦截器
service.interceptors.request.use(config => {
  start()
  const { noToken = false } = config
  if (noToken) return config
  const token = userDB.get('token')
  // const token = userDB.get('token') || '756DeyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhYjMxNWNhOS1kNjQ0LTQwOWQtYWU1My1jY2I2ZTRhNDFjZjIiLCJhdWQiOiJWSVAiLCJjcmVhdGVkIjoxNTMyNDI0NjE3NjQyLCJleHAiOjE1MzMwMjk0MTd9.UbfidghQDiWThqJnfl3VwIXaZ5uvy719_0ubetWOjTBIY2rmAR_2ehB3gPqKIoK4YZPn-EPiVs6c8Pbi3RUIcQ'
  if (token) {
    config.headers['token'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
  } else {
    router.push({ name: 'login' })
  }
  if (config.params) {
    config.params = paramFilter(config.params)
  }
  if (config.data) {
    config.data = paramFilter(config.data)
  }
  if (config.url) {
    URL = config.url
  }
  // console.log(config, 'config')
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  return Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  res => {
    end()
    const { data = {}} = res
    // console.log(data, 'response data!')
    if (typeof data === 'string') return data
    const { success, message, result } = data
    if (success) {
      if (!result) {
        // Vue.prototype.$toast({
        //   message
        // })
        console.log(message)
      }
      return result
    } else {
      console.error(message)
      return Promise.reject(new Error(message))
    }
  },
  /**
  * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
  * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中
  */
  //  const res = response.data;
  //     if (res.code !== 20000) {
  //       Message({
  //         message: res.message,
  //         type: 'error',
  //         duration: 5 * 1000
  //       });
  //       // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
  //       if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
  //         MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
  //           confirmButtonText: '重新登录',
  //           cancelButtonText: '取消',
  //           type: 'warning'
  //         }).then(() => {
  //           store.dispatch('FedLogOut').then(() => {
  //             location.reload();// 为了重新实例化vue-router对象 避免bug
  //           });
  //         })
  //       }
  //       return Promise.reject('error');
  //     } else {
  //       return response.data;
  //     }
  error => {
    end()
    const errUrlMessage = `错误接口名称：${URL}`
    console.log('err' + error + ';' + errUrlMessage)// for debug
    // Vue.prototype.$toast
    console.error({
      message: `${error.message};${errUrlMessage}`,
      type: 'fail'
    })
    if (URL && URL !== '') {
      Dialog.alert({
        title: '错误接口',
        message: `错误接口名称：${URL}`
      })
    }
    return Promise.reject(error)
  })

export default service
