import userDB from '@/utils/request'

export function getAppid(query) {
  let appid = ''
  const regAppId = new RegExp('(^|\\?|&)' + 'appid' + '=([^&]*)(\\s|&|$)', 'i')

  if (regAppId.test(window.location.href)) {
    appid = unescape(RegExp.$2.replace(/\+/g, ' '))
    if (appid.indexOf('#') > 0) {
      appid = appid.split('#')[0]
    }
  }
  console.log(appid, 'appid')
  return appid
}
export function setRedirectUrl() {
  let redirectUrl = null
  let reportDetailId = null
  const regRedirectUrl = new RegExp('(^|\\?|&)' + 'redirectUrl' + '=([^&]*)(\\s|&|$)', 'i')
  const regReportDetailId = new RegExp('(^|\\?|&)' + 'reportDetailId' + '=([^&]*)(\\s|&|$)', 'i')
  if (regRedirectUrl.test(window.location.href)) {
    redirectUrl = unescape(RegExp.$2.replace(/\+/g, ' '))
    if (redirectUrl.indexOf('#') > 0) {
      redirectUrl = redirectUrl.split('#')[0]
    }
  }

  if (regReportDetailId.test(window.location.href)) {
    reportDetailId = unescape(RegExp.$2.replace(/\+/g, ' '))
    if (reportDetailId.indexOf('#') > 0) {
      reportDetailId = reportDetailId.split('#')[0]
    }
  }
  return { redirectUrl, reportDetailId }
}
