<template>
  <div class="wrapper">
    <div class="title">
      <span class="text">{{ title }}</span>
      <van-icon class="icon" name="guanbi" v-tap @tap.native="close" />
    </div>
    <div class="content">f</div>
    <div class="footer">
      <div class="btn" v-tap @tap.native="ok"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: [String],
  },
  created () {

  },
  methods: {
    close () {
      this.$parent.cancel()
    },
    ok () {
      this.$parent.ok()
    }
  }
}
</script>
<style lang="scss" scoped>
  .wrapper {
    padding: 50px 60px;
    .title {
      display: flex;
      justify-content: space-between;
      .text {
        font-size: 42px;
        color: #333;
      }
      .icon {
        font-size: 37px;
      }
    }
    .content {
      min-height: 300px;
    }
    .footer {
      padding: 0 66px;
      .btn {
        width: 503px;
        height: 83px;
        background: rgba(242,72,82,1);
        border-radius: 42px;
      }
    }
  }
</style>
