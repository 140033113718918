import api from '@/api'

const { getOrderList, getOrderDetail, orderRate } = api
const GET_ORDER_LIST = 'GET_ORDER_LIST'
const GET_ORDER_DETAIL = 'GET_ORDER_DETAIL'

const state = {
  orders: []
}

const getters = {}

const actions = {
  async fetchOrderList({ commit }, value) {
    try {
      const orders = await getOrderList(value)
      const { list = [] } = orders || {}
      commit(GET_ORDER_LIST, list)
      return list
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async fetchOrderDetail({ commit }, id) {
    try {
      const detail = await getOrderDetail(id)
      return detail
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async userOrderRate({ commit }, value) {
    try {
      const res = await orderRate(value)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const mutations = {
  [GET_ORDER_LIST](state, payload) {
    state.orders = payload
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
