import confirm from './confirm'

export default {
  install(Vue) {
    Vue.prototype.$vConfirm = function({
      ok = function() {},
      cancel = function() {},
      closeable = true,
      visible = false,
      transition = 'up',
      position = 'center',
      flex = position === 'flex' ? true : false,
      styles,
      modalStyles,
      mask,
      opacity,
      store,
      router,
      data = function() {
        return {}
      },
      target
    } = {}) {
      return Vue.prototype.$vModal({
        ok,
        cancel,
        closeable,
        visible,
        transition,
        flex,
        styles,
        modalStyles,
        mask,
        opacity,
        store,
        router,
        data,
        target,
        children: {
          component: confirm
        }
      })
    }
  }
}
