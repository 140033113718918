import api from '@/api/index'

const { getAds, getShopList, getShopDetail, getEmployees, getPicc, getVideos, getActivity } = api

// const GET_ADS = 'GET_ADS'
const GET_SHOP_LIST = 'GET_SHOP_LIST'
const GET_POSITION = 'GET_POSITION'

const state = {
  // ads: [],
  shops: [],
  lat: '',
  lng: ''
}

const getters = {
  hasLbs: state => !!state.lat && !!state.lng
}

const actions = {
  async fetchAdList({ commit }) {
    try {
      const ads = await getAds()
      // commit(GET_ADS, ads.list)
      const { list = [] } = ads
      return list
    } catch (error) {
      console.log(error, 'get ads failed')
      return Promise.reject(error)
    }
  },
  async fetchShopList({ commit }, value) {
    try {
      const shops = await getShopList(value)
      // commit(GET_SHOP_LIST, shops)
      return shops
    } catch (error) {
      console.log(error, 'get shops failed')
      return Promise.reject(error)
    }
  },
  async fetchShopDetail({ commit }, shopId) {
    try {
      const shop = await getShopDetail(shopId)
      return shop
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async fetchActivity({ commit }, value) {
    try {
      const activity = await getActivity(value)
      return activity.list
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async fetchEmployees({ commit }, value) {
    try {
      const employees = await getEmployees(value)
      return employees.list
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async fetchPicc({ commit }, value) {
    try {
      const picc = await getPicc(value)
      return picc.list
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async fetchVideos({ commit }, value) {
    try {
      const videos = await getVideos(value)
      return videos.list
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const mutations = {
  // [GET_ADS] (state, ads) {
  //   state.ads = ads
  // },
  [GET_SHOP_LIST](state, shops) {
    state.shops = shops
  },
  [GET_POSITION](state, position) {
    const { lat = '', lng = '' } = position || {}
    state.lat = lat
    state.lng = lng
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
