import global from './global'
import user from './user'
// import permission from './permission'
import home from './home'
import book from './book'
import order from './order'

export default {
  home,
  global,
  user,
  // permission,
  book,
  order
}
