// import Cookies from 'js-cookie'

const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR'
const TOGGLE_DEVICE = 'TOGGLE_DEVICE'
const SET_DATE = 'SET_DATE'

const state = {
  sidebar: {
    // opened: !+Cookies.get('sidebarStatus'),
    opened: false,
    withoutAnimation: false
  },
  device: 'desktop',
  currentYear: 0,
  currentMonth: 0,
  currentDay: 0
}

const getters = {
  sidebar: state => state.sidebar,
  device: state => state.device
}

const actions = {
  ToggleSideBar({ commit }) {
    commit(TOGGLE_SIDEBAR)
  },
  CloseSideBar({ commit }, { withoutAnimation }) {
    commit(CLOSE_SIDEBAR, withoutAnimation)
  },
  ToggleDevice({ commit }, device) {
    commit(TOGGLE_DEVICE, device)
  }
}

const mutations = {
  [TOGGLE_SIDEBAR](state) {
    state.sidebar.opened = !state.sidebar.opened
    // state.sidebar.withoutAnimation = false
  },
  [CLOSE_SIDEBAR](state, withoutAnimation) {
    // Cookies.set('sidebarStatus', 1)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  [TOGGLE_DEVICE](state, device) {
    state.device = device
  },
  [SET_DATE](state) {
    const data = new Date()
    state.currentYear = data.getFullYear()

    if (data.getMonth() >= 9) {
      state.currentMonth = data.getMonth() + 1
    } else {
      state.currentMonth = `0${data.getMonth() + 1}`
    }

    if (data.getDate() > 9) {
      state.currentDay = data.getDate()
    } else {
      state.currentDay = `0${data.getDate()}`
    }
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
