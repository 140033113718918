import isString from 'lodash/isString'

const _localStorage = (() => {
  const _db = window.localStorage
  return {
    set(key, value) {
      _db && _db.setItem(key, value)
    },
    remove(key) {
      _db && _db.removeItem(key)
    },
    get(key) {
      return _db ? _db.getItem(key) : null
    },
    clear() {
      _db && _db.clear()
    }
  }
})()

const _sessionStorage = (() => {
  const _db = window.sessionStorage
  return {
    set(key, value) {
      _db && _db.setItem(key, value)
    },
    remove(key) {
      _db && _db.removeItem(key)
    },
    get(key) {
      return _db ? _db.getItem(key) : null
    },
    clear() {
      _db && _db.clear()
    }
  }
})()

export default (function() {
  const store = {}
  return function(domain, useSession) {
    if (!isString(domain)) {
      throw new Error(`Expect namespace to be string, but accept type ${typeof domain}`);
    }
    if (!store[domain]) {
      // console.log('db init', domain, useSession)
      store[domain] = useSession ? _sessionStorage : _localStorage
    }
    return store[domain]
  }
})()
