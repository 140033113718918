<template>
  <transition name="modal" :appear="true" @before-enter="beforeEnter" @after-enter="afterEnter" @before-leave="beforeLeave" @after-leave="afterLeave">
    <div v-if="seen" :class="classes" :style="modalStyles">
      <div v-if="mask" class="mask" v-tap.prevent.stop @tap.native.self="maskhit" :style="maskStyle"></div>
      <div ref="modal" class="modal-dialog" :style="style">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'modal',
    props: {
      transition: {
        type: String,
        default: 'up' // [fade, scale, ]
      },
      position: {
        type: String,
        default: 'flex' // [top, flex, bottom]
      },
      flex: {
        type: Boolean,
        default: false
      },
      styles: {
        type: Object,
        default: null
      },
      modalStyles: {
        type: Object,
        default: null
      },
      visible: {
        type: Boolean,
        default: false
      },
      closeable: {
        type: Boolean,
        default: true
      },
      global: {
        type: Boolean,
        default: false
      },
      mask: {
        type: Boolean,
        default: true
      },
      opacity: {
        type: Number,
        default: 0.6
      }
    },
    data() {
      return {
        destroyed: false,
        seen: this.visible
      }
    },
    computed: {
      classes() {
        return ['modal', this.flex ? 'modal-flex' : `modal-${this.position}`, this.transition ? `modal-${this.transition}` : '']
      },
      style() {
        // let defaultStyle = this.flex ? {} : {top: '10%'};
        const defaultStyle = {}
        Object.assign(defaultStyle, this.styles)
        return defaultStyle
      },
      maskStyle() {
        return {
          background: `rgba(0,0,0,${this.opacity})`
        }
      }
    },
    watch: {},
    created() {
      this.target = this.global && this.$parent.target ? this.$parent.target : document.body
      // this.$root.$el.appendChild(this.$el);
      !this.global && this.$nextTick(() => {
        this.target.appendChild(this.$el)
      })
    },
    mounted() {},
    beforeDestroy() {},
    destroyed() {
      // console.log('child destroyed');
      this.target.removeChild(this.$el)
    },
    methods: {
      open() {
        if (!this.seen) {
          this.seen = true
        }
      },
      close() {
        if ((!!this.seen) && true) {
          this.seen = false
        }
      },
      ok(data) {
        this.close()
        this.$emit('ok', data)
      },
      cancel(data) {
        this.close()
        this.$emit('cancel', data)
      },
      maskhit() {
        if ((!!this.closeable) && true) {
          this.cancel()
        }
      },
      beforeEnter() {
        this.$emit('open')
      },
      afterEnter() {
        this.$emit('opened')
      },
      beforeLeave() {
        this.$emit('close')
      },
      afterLeave() {
        this.$emit('closed')
        if (this.global) {
          this.$destroy()
          this.$parent.remove()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    z-index: 999;
    text-align: center;
  }
  
  .modal-dialog {
    position: relative;
    margin: 0 auto;
    display: inline-block;
    text-align: left;
    width: 640px;
  }
  
  .modal-top {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .modal-bottom {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  
  .modal-flex,
  .modal-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .mask {
    width: 100%;
    height: 100%;
    display: block;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .modal-enter-active,
  .modal-leave-active {
    transition: all 0.35s;
    .modal-dialog {
      transition: transform 0.35s;
    }
  }
  
  .modal-enter,
  .modal-leave-active {
    opacity: 0;
    &.modal-up {
      .modal-dialog {
        transform: translateY(30%);
      }
    }
    &.modal-down {
      .modal-dialog {
        transform: translateY(-30%);
      }
    }
    &.modal-scale {
      .modal-dialog {
        transform: scale(0.8);
      }
    }
  }
</style>
