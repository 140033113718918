import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

export const constantRouterMap = [
  {
    path: '/',
    redirect: '/home',
    meta: {
      title: '电小二'
    }
  },
  {
    path: '/init',
    name: 'init',
    component: () =>
      import('@/views/init/index')
  },
  {
    path: '/callBack',
    name: 'callback',
    meta: {
      isWxCallback: true
    },
    component: () =>
      import('@/views/init/callBack')
  },
  {
    name: 'login',
    path: '/login',
    component: () =>
      import('@/views/login/index'),
    meta: {
      title: '登录'
    }
  },
  {
    name: 'home',
    path: '/home',
    meta: {
      title: '电小二'
    },
    component: () =>
      import('@/views/home/home')
  }, // 首页
  {
    name: 'pay',
    path: '/pay',
    meta: {
      title: '充值'
    },
    component: () =>
      import('@/views/pay/pay')
  }, // 充值页
  {
    name: 'balance',
    path: '/balance',
    meta: {
      title: '账户余额'
    },
    component: () =>
      import('@/views/pay/balance_account')
  }, // 账户余额
  {
    name: 'vipUsedReport',
    path: '/vipUsedReport',
    meta: {
      title: '商户用电报告'
    },
    component: () =>
      import('@/views/vipUsedReport/vipUsedReport')
  }, // 商户用电报告
  {
    name: 'useDay',
    path: '/useDay',
    meta: {
      title: '预计使用天数'
    },
    component: () =>
      import('@/views/pay/use_day')
  }, // 预计使用天数
  {
    name: 'userInfor',
    path: '/userInfor',
    meta: {
      title: '用户信息'
    },
    component: () =>
      import('@/views/userinfor/userinfor')
  }, // 用户信息页
  {
    name: 'energyConsumption',
    path: '/energyConsumption',
    meta: {
      title: '表计详情'
    },
    component: () =>
      import('@/views/energy_consumption/energy_consumption')
  }, // 能耗页
  {
    name: 'expenses',
    path: '/expenses',
    meta: {
      title: '扣费明细'
    },
    component: () =>
      import('@/views/expenses/expenses')
  }, // 结算明细
  {
    name: 'expensesPay',
    path: '/expensesPay',
    meta: {
      title: '充值明细'
    },
    component: () =>
      import('@/views/expenses/expenses_pay')
  }, // 充值明细
  {
    name: 'meterExpenses',
    path: '/meterExpenses',
    meta: {
      title: '表计结算明细'
    },
    component: () =>
      import('@/views/expenses/meter_expenses')
  }, // 表计结算明细
  {
    name: 'feedback',
    path: '/feedback',
    meta: {
      title: '意见反馈'
    },
    component: () =>
      import('@/views/help/feedback')
  }, // 意见反馈
  {
    name: 'complaint',
    path: '/complaint',
    meta: {
      title: '投诉意见'
    },
    component: () =>
      import('@/views/complaint/complaint')
  }, // 投诉意见
  {
    name: 'complaintDetail',
    path: '/complaintDetail',
    meta: {
      title: '投诉意见'
    },
    component: () =>
      import('@/views/complaint/complaintDetail')
  }, // 投诉意见-详情
  {
    name: 'complaintSubmit',
    path: '/complaintSubmit',
    meta: {
      title: '投诉意见'
    },
    component: () =>
      import('@/views/complaint/complaintSubmit')
  }, // 投诉意见-详情
  {
    path: '/404',
    meta: {
      title: '404'
    },
    component: () =>
      import('@/views/errorPage/404')
  },
  {
    path: '*',
    redirect: '/404'
  }
]
export default new Router({
  base: '/wechat-vip-client/',
  // mode: 'history', // 后端支持可开
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop
      }
      return { x: 0, y: to.meta.savedPosition || 0 }
    }
  },
  routes: constantRouterMap
})
