export default {
  install (Vue) {
    Vue.mixin({
      methods: {
        trancTag (code) {
          let tag = ''
          switch (code) {
            case 'MAINTAIN':
              tag = '保养';
              break;
            case 'METALER':
              tag = '饭喷';
              break;
            case 'REPAIR':
              tag = '维修';
              break;
            case 'WASH':
              tag = '清洗';
              break;
          }
          return tag
        },
        project2Icon (code) {
          let icon = ''
          switch (code) {
            case 'MAINTAIN':
              icon = 'baoyang'
              break
            case 'METALER':
              icon = 'banpen'
              break
            case 'REPAIR':
              icon = 'weixiu'
              break
            case 'WASH':
              icon = 'xiche'
              break
          }
          return icon
        },
        bookState2Tag (code) {
          let tag = ''
          switch (code) {
            case 'WAIT_PICKUP':
              tag = '预约中'
              break
            case 'ACCEPT':
              tag = '已完成'
              break
            case 'CANCEL':
              tag = '已取消'
              break
            case 'REFUSE':
              tag = '已拒绝'
              break
          }
          return tag
        },
        orderState2Tag (code) {
          let tag = ''
          switch (code) {
            case 'SERVERING':
              tag = '服务中'
              break
            case 'FINISH':
              tag = '已完成'
              break
            case 'CANCEL':
              tag = '已取消'
              break
          }
          return tag
        }
      }
    })
  }
}
