import api from '@/api'
import merge from 'lodash/merge'

const { getBookList, getBookDetail, bookProject, cancelBook } = api
const GET_BOOK_LIST = 'GET_BOOK_LIST'
const SELECT_BOOK_SHOP = 'SELECT_BOOK_SHOP'

const state = {
  bookList: [],
  selBookShop: {}
}

const getters = {}

const actions = {
  async fetchBookList({ commit }, value) {
    try {
      const bookList = await getBookList(value)
      const { list } = bookList || {}
      commit(GET_BOOK_LIST, list)
      return list
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async fetchBookDetail({ commit }, id) {
    try {
      const detail = await getBookDetail(id)
      return detail
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async dispatchBook({ commit }, value) {
    try {
      const res = await bookProject(value)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async cancelBooking({ commit }, value) {
    try {
      const res = await cancelBook(value)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const mutations = {
  [GET_BOOK_LIST](state, payload) {
    state.bookList = payload
  },
  [SELECT_BOOK_SHOP](state, payload) {
    // state.selBookShop = merge({}, state.selBookShop, payload)
    state.selBookShop = payload
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
