import Vue from 'vue'
import App from './App'
import router from '@/router'
import store from '@/store'
import mixin from './mixin'
import tap from '@/plugins/tap'
import VueProgressBar from 'vue-progressbar'
import modal from '@/components/modal'
import confirm from '@/components/confirm'

import '@/icons' // icon
import '@/permission' // permission control

// 引入weui
import weui from 'weui.js'
import 'weui'
Vue.prototype.$weui = weui

import wx from 'weixin-js-sdk'
Vue.prototype.$wx = wx; //写不写这个 在组件中引用wx的时候都是undefined

import Vant from 'vant'
import {
  Lazyload,
  List,
  Toast
} from 'vant'

import 'vant/lib/index.css'
//import 'vant/lib/vant-css/icon-local.css'

const options = {
  color: '#00A39B',
  failedColor: '#ed4851',
  thickness: '2px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  // location: 'left',
  inverse: false
}

Vue.use(tap)
Vue.use(mixin)
Vue.use(VueProgressBar, options)
Vue.use(Vant)
Vue.use(weui)
Vue.use(Lazyload)
Vue.use(modal)
Vue.use(confirm)
Vue.use(Toast)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
