<template>
  <div id="app">
    <vue-progress-bar></vue-progress-bar>
    <transition name="router-fade" mode="out-in">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
    </transition>
    <transition name="router-fade" mode="out-in">
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </transition>
    <div v-if="icoFlag" :src="refreshIco" class="img_home"  ref="img_home_ico"  @touchstart="touchstartHandle('img_home_ico',$event)" @touchmove="touchmoveHandle('img_home_ico',$event)" @click="refresh()"> </div>
    <div class="img_home" ref="img_home"  @touchstart="touchstartHandle('img_home',$event)" @touchmove="touchmoveHandle('img_home',$event)">
      <span class="content_span" v-if="icoFlag" @click="refresh()">
        <img :src="refreshIco" />
      </span>

      <span class="content_span" v-if="showImg" @click="$router.go(-1)">
        <img :src="prevIco" class="prev_ico"/>
      </span>

      <span class="content_span" v-if="showImg" @click="goPage('/home')">
        <img :src="toHome" />
      </span>
    </div>
    <div v-if="complaintFlag" class="img_div" ref="img_div" :style="showFlag ? 'background:#a8a8a8;width:186px' : 'background:none;width:60px'" @touchstart="touchstartHandle('img_div',$event)" @touchmove="touchmoveHandle('img_div',$event)">
      <div @click="goPage('/home')" v-if="showFlag">
        <img :src="iconHome" class="top_img"/>
        <span>首页</span>
      </div>
      <div @click="goPage('/complaintSubmit')"  v-if="showFlag">
        <img :src="iconCom" class="top_img"/>
        <span>投诉</span>
      </div>
      <div class="icon_add_box" @click="showFlag = !showFlag">
        <!-- <img :src="iconAdd" class="icon_add" @click="showFlag = !showFlag"/> -->
        <span class="orientation"></span>
        <span class="portrait"></span>
      </div>
    </div>
  </div>
  <!-- <style src="./fonts/index.css"></style> -->
</template>

<script>
  import toHome from '@/assets/home/home.png'
  // import refreshIco from '@/assets/home/refresh_ico.png'
  import refreshIco from '@/assets/home/refresh.png'
  import iconAdd from '@/assets/img/add_2.png'
  import iconHome from '@/assets/img/icon_home.png'
  import prevIco from '@/assets/home/back1.png'
  import iconCom from '@/assets/img/icon_complaint.png'
  import { userDB } from '@/db'
  import VConsole from 'vconsole/dist/vconsole.min.js' // import vconsole
  if (process.env.NODE_ENV !== 'production') {
    const vConsole = new VConsole() // 初始化
  }
  export default {
    name: 'App',
    data() {
      return {
        iconAdd,
        iconHome,
        iconCom,
        toHome,
        refreshIco,
        prevIco,
        icoFlag: true,
        complaintFlag: false,
        showImg: true,
        showFlag: false,
        coordinate: {
          client: {},
          elePosition: {}
        }
      }
    },
    watch: {
      $route(to, from) {
        if (to.name === 'home') {
          this.showImg = false
          this.icoFlag = true
          this.complaintFlag = false
        } else if (to.name === 'login') {
          this.showImg = false
          this.icoFlag = false
          this.complaintFlag = false
        } else if (to.name === 'complaint') {
          this.showImg = false
          this.icoFlag = false
          this.complaintFlag = true
        } else {
          this.showImg = true
          this.icoFlag = false
          this.complaintFlag = false
        }
      }
    },
    methods: {
      // 刷新
      refresh() {
        // location.reload()
        this.refresh1()
      },
      // 刷新
      refresh1() {
        const appidName = userDB.get('appId')
        localStorage.clear()
        userDB.set('appId', appidName)
        this.$store.dispatch('getWxPath').then(url => {
        // TODO 跳转微信页面,暂时注释,下面两行注释掉还原newx()可以直接接入本地开发环境，反之拦截进入微信
          location.href = decodeURIComponent(url)
        })
      },
      addComplaint() {

      },
      goPage(name) {
        this.$router.push({
          'path': `${name}`
        })
      },
      touchstartHandle(refName, e) {
        const element = e.targetTouches[0]
        // 记录点击的坐标
        this.coordinate.client = {
          x: element.clientX,
          y: element.clientY
        }
        // 记录需要移动的元素坐标
        this.coordinate.elePosition.left = this.$refs[refName].offsetLeft
        this.coordinate.elePosition.top = this.$refs[refName].offsetTop
      },
      touchmoveHandle(refName, e) {
        const element = e.targetTouches[0]
        // 根据初始 client 位置计算移动距离(元素移动位置=元素初始位置+光标移动后的位置-光标点击时的初始位置)
        let x = this.coordinate.elePosition.left + (element.clientX - this.coordinate.client.x)
        let y = this.coordinate.elePosition.top + (element.clientY - this.coordinate.client.y)
        // 限制可移动距离，不超出可视区域
        x = x <= 0 ? 0 : x >= innerWidth - this.$refs[refName].offsetWidth ? innerWidth - this.$refs[refName].offsetWidth : x
        y = y <= 0 ? 0 : y >= innerHeight - this.$refs[refName].offsetHeight ? innerHeight - this.$refs[refName].offsetHeight : y
        // 移动当前元素
        // this.$refs[refName].style.left = x + 'px'
        this.$refs[refName].style.top = y + 'px'
      }
    }
  }
</script>

<style lang="scss">
  // @import './style/common';
  .router-fade-enter-active,
  .router-fade-leave-active {
    transition: opacity .3s;
  }

  .router-fade-enter,
  .router-fade-leave-active {
    opacity: 0;
  }

  body,html{
    font-size: 30px;
    background: #fff;
  }

  .img_home {
    width:40px;
    height: auto;
    position: fixed;
    z-index: 10;
    bottom:2rem;
    right: 1rem;
    .content_span {
      display: inline-block;
      width: 40px;
      height: 40px;
      background: #fff;
      box-shadow: 3px 5px 5px #dadada;
      text-align: center;
      img {
        width: 60%;
        margin: 20%;
      }
      .prev_ico {
        width: 60%;
        margin: 20%;
      }
    }
  }

  .img_div {
    width:372px;
    padding: 0;
    height: 120px;
    position: fixed;
    z-index: 10;
    bottom:1rem;
    border-radius:150px ;
    right: 1rem;
    background: #a8a8a8;
    overflow: hidden;
    .icon_add_box {
      background: #FFBE3E;
      border-radius:120px;
      position: relative;
      width: 120px;
      span {
        position: absolute;
        display: inline-block;
        background: #fff;
        border-radius:50px;
      }
      .orientation {
        width: 60px;
        height: 6px;
        float: left;
        margin-top: 55px;
        margin-left: 30px;
        z-index: 2;
        left: 0;
        top: 0;
      }
      .portrait {
        z-index: 1;
        width: 6px;
        height: 60px;
        margin-top: 30px;
        margin-left: 55px;
        left: 0;
        top: 0;
      }
    }
    div {
      width: 30%;
      height: 120px;
      margin-left: 2.5%;
      text-align: center;
      color: #fff;
      float: left;
      font-size: 24px;
      .top_img {
        width: 40%;
        margin-top: 20px;
      }
      .icon_add {
        height: 120px;
        float: left;
      }
      span {
        display: inline-block;
        width: 100%;
      }
    }
    div:last-child {
      float: right;
    }
  }

  body, html, #app {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }

  #complaintSubmit {
    .van-field__control:disabled {
      color: #333;
    }
  }

  #expenses .tab_header {
    .van-collapse-item__wrapper .van-collapse-item__content {
      background: #F5F5F5;
    }
    .van-cell__value {
      color: #000;
      font-size: 1rem;
    }
    .van-cell__title {
      font-size: 0.9rem;
    }
  }

  .vip_report {
    font-size:1rem;
    .fontBox {
      .van-collapse-item__content {
        background: #FAFAFA;
      }
      .van-step__circle {
        background-color: #68A6FD;
      }
      .van-cell__title{
        color:#999FAA;
      }
      .van-cell__value {
        color: #333;
      }
      .van-cell {
        font-size:32px;
        padding: 30px 30px;
      }
      .van-collapse-item__content {
        padding:0;
      }
      .van-steps {
        background: #FAFAFA;
      }
      .van-step--vertical:first-child::before {
        background: #FAFAFA;
      }
    }
    .report_group {
      .field_box {
        font-size:1rem !important;
        padding: 30px 16px !important;
        .van-cell__title {
          max-width: 240px;
          color:#999FAA;
        }
        .van-field__control {
          text-align: right;
          color:#333;
        }
        .van-cell__value {
          color:#333;
        }
      }
      .field_box:not(:last-child)::after {
        border-bottom: 1px solid #E5E5E5;
      }
      .long_field_box {
        .van-cell__title {
          max-width: 580px;
          flex: 3;
        }
      }
    }
  }



  @media screen and ( min-width: 300px ) and ( max-width: 370px ) {
    body,html{
      font-size: 14px;
    }
  }
  @media screen and ( min-width: 370px ) and ( max-width: 400px ) {
    body,html{
      font-size: 16px;
    }
  }

  @media screen and ( min-width: 400px ) and ( max-width: 430px ) {
    body,html{
      font-size: 18px;
    }
  }

  @media screen and ( min-width: 430px ) and ( max-width: 460px ) {
    body,html{
      font-size: 18px;
    }
  }

  @media screen and ( min-width: 460px ) and ( max-width: 520px ) {
    body,html{
      font-size: 20px;
    }
  }

  @media screen and ( min-width: 520px ) and ( max-width: 560px ) {
    body,html{
      font-size: 22px;
    }
  }

  @media screen and ( min-width: 560px ) and ( max-width: 620px ) {
    body,html{
      font-size: 24px;
    }
  }

  @media screen and ( min-width: 620px ) and ( max-width: 680px ) {
    body,html{
      font-size: 26px;
    }
  }

  @media screen and ( min-width: 680px ) and ( max-width: 740px ) {
    body,html{
      font-size: 28px;
    }
  }

  @media screen and ( min-width: 740px ) and ( max-width: 800px ) {
    body,html{
      font-size: 30px;
    }
  }

  @media screen and ( min-width: 800px ) and ( max-width: 860px ) {
    body,html{
      font-size: 32px;
    }
  }

  @media screen and ( min-width: 860px ) and ( max-width: 900px ) {
    body,html{
      font-size: 34px;
    }
  }

  @media screen and ( min-width: 900px ) and ( max-width: 960px ) {
    body,html{
      font-size: 36px;
    }
  }

  @media screen and ( min-width: 960px ) and ( max-width: 1020px ) {
    body,html{
      font-size: 38px;
    }
  }

  @media screen and ( min-width: 1020px ) and ( max-width: 1120px ) {
    body,html{
      font-size: 42px;
    }
  }

  @media screen and ( min-width: 1120px ) and ( max-width: 1200px ) {
    body,html{
      font-size: 46px;
    }
  }

  @media screen and ( min-width: 1200px ) and ( max-width: 1280px ) {
    body,html{
      font-size: 48px;
    }
  }

  @media screen and ( min-width: 1280px ) and ( max-width: 1400px ) {
    body,html{
      font-size: 50px;
    }
  }

  @media screen and ( min-width: 1400px ) and ( max-width: 1500px ) {
    body,html{
      font-size: 58px;
    }
  }

  @media screen and ( min-width: 1500px ) and ( max-width: 1600px ) {
    body,html{
      font-size: 66px;
    }
  }

</style>
