// import _ from 'lodash'
import router from './router'
import store from './store'
import {
  userDB
} from '@/db'
import {
  getAppid,
  setRedirectUrl
} from '@/api/getUrlParmas'

// const whiteList = ['/init', '/login', '/exclusive', '/exclusiveList', '/piccNet', '/insuranceConsult', '/book', '/staff', '/home', '/detail', '/videoDetail'] // 不重定向白名单
const date = new Date()
const versionNumber = `1.049364${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}`
const getUrl = () => {
  localStorage.clear()
  const appid = getAppid()
  if (appid && appid !== '') {
    userDB.set('appId', appid)
    store.commit('SET_APPID', appid)
  }

  userDB.set('version', versionNumber)
  store.dispatch('getWxPath').then(url => {
    // TODO 跳转微信页面,暂时注释,下面两行注释掉还原newx()可以直接接入本地开发环境，反之拦截进入微信
    location.href = decodeURIComponent(url)
    const redirectParmas = setRedirectUrl()
    if (redirectParmas && redirectParmas.redirectUrl) {
      userDB.set('redirectParmas', JSON.stringify(redirectParmas))
    }
    // next()
  })
}

// 本地开发配置
// window.localStorage.setItem('token', '756DeyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMjVkMWYzNy1jOTY0LTQzYzctYThiNS01YjA1MTRkNWM2YWUiLCJhdWQiOiJWSVAiLCJjcmVhdGVkIjoxNjE0MzA0MTEwNzk1LCJvcGVuSWQiOiJvY2NVNnQ3QkdfaU03ZG5jQUVMTnMxTDh5MHR3IiwiZXhwIjoxNjE0OTA4OTEwfQ.61mwAewGvT2MiLlvFZJFjfT36bxuiz0HCzCgGo5PHbA_BYSmyL1ghKNeGCj3I9RI_eu8zSVaLG_VS2v9nT6MSA')
// window.localStorage.setItem('vipId', '125d1f37-c964-43c7-a8b5-5b0514d5c6ae')

// window.localStorage.setItem('token', '756DeyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMjVkMWYzNy1jOTY0LTQzYzctYThiNS01YjA1MTRkNWM2YWUiLCJhdWQiOiJWSVAiLCJjcmVhdGVkIjoxNTk1Mzg1NjUyOTY0LCJvcGVuSWQiOiJvY2NVNnQ3QkdfaU03ZG5jQUVMTnMxTDh5MHR3IiwiZXhwIjoxNTk1OTkwNDUyfQ.MZWcp-QQcGVcLSZUxW-2PNBBU9OvGv3xMK4wuHxXlG9-nTrVI74csQMf-47RT1nfNR4iCu5yMGFRCh_ivBhfnw')
// window.localStorage.setItem('vipId', '125d1f37-c964-43c7-a8b5-5b0514d5c6ae')

/*
  路由拦截
*/
router.beforeEach((to, from, next) => {
  const vipId = userDB.get('vipId')
  const token = userDB.get('token')
  const appidName = userDB.get('appId')

  const {
    meta = {}, name
  } = to
  const {
    isWxCallback = false
  } = meta
  if (to.meta.title) {
    if (to.name === 'expenses') {
      document.title = to.query.id ? '表计结算明细' : to.meta['title']
    } else {
      document.title = to.meta['title']
    }
  }

  if ((name === 'login') || (to.fullPath.indexOf('login') >= 0)) {
    next()
  } else if ((vipId) && (token)) {
    const version = userDB.get('version')
    if ((name === 'home') && (version) && (version !== versionNumber)) {
      userDB.set('version', versionNumber)
      getUrl()
    } else if ((name === 'home') && (!!getAppid()) && (getAppid() !== appidName)) {
      getUrl()
    }
    next()
  } else {
    if (!isWxCallback) {
      const appid = getAppid()
      if (appid && appid !== '') {
        userDB.set('appId', appid)
        store.commit('SET_APPID', appid)
      }

      getUrl()
    } else {
      next()
    }
  }
})

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  }
})

router.afterEach(() => {})
